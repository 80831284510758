import axios, { AxiosResponse } from "axios";
import router from "@/router";
import i18n from "@/i18n";
import SortingOrdinalNumbers from "@/utils/SortingHelper";
//TS interface
import Project from "@/model/Project";
import Section from "@/model/ModelSection";
import {
  ProjectStatus,
  Interval,
  CameraSettings,
  ProjectProfile,
  ReportOptions,
  MeasureReportOptions
} from "@/interface/projectsInterface";

const Projects = {
  createAxiosInstance() {
    const axiosProjects = axios.create({
      baseURL: process.env.VUE_APP_PROJECT,
      headers: {
        // @ts-ignore
        Authorization: "Bearer " + router.app.$keycloak.token,
        Accept: "application/json"
        // 'Content-Type': 'application/json'
      }
    });
    return axiosProjects;
  },
  async allProjects() {
    const res = await this.createAxiosInstance().get("/api/v1/project");
    return res;
  },
  async getAllActiveProjectsWithSections() {
    const res = await this.createAxiosInstance().get("/api/v1/project-active-with-sections");
    return res;
  },
  async getAllActiveProjectsForUserWithSections(userId: string) {
    const res = await this.createAxiosInstance().get(`/api/v1/project-active-for-user-with-sections/${userId}`);
    return res;
  },
  async allProjectsList() {
    return await this.createAxiosInstance().get("/api/v1/project-all");
  },
  async allArchivedProjects() {
    const res = await this.createAxiosInstance().get("/api/v1/project_archive");
    return res;
  },
  async allProjectsForUser(userId: string) {
    const res = await this.createAxiosInstance().get(`/api/v1/projects_by_user/${userId}`);
    return res;
  },
  async allStatus() {
    const res = await this.createAxiosInstance().get("/api/v1/project-status");
    return res.data.projectStatusList;
  },
  async allProjectTypes() {
    const res = await this.createAxiosInstance().get("/api/v1/project-types");
    return res.data.projectTypeList;
  },
  async CreateNewProject(newProject: Project) {
    const res = await this.createAxiosInstance().post("/api/v1/project", newProject);
    return res;
  },
  async DetailsProject(id: string) {
    const res = await this.createAxiosInstance().get(`/api/v1/project/${id}`);

    return res;
  },
  async UpdateCurrentProject(projectToUpdate: Project) {
    const res = await this.createAxiosInstance().put(`/api/v1/project/${projectToUpdate.id}`, projectToUpdate);
    return res;
  },
  async DeleteProject(id: string) {
    const res = await this.createAxiosInstance().delete(`/api/v1/project/${id}`, {
      data: { id: id }
    });
    return res.status;
  },
  async deleteAll() {
    return await this.createAxiosInstance().delete(`/api/v1/project/delete-all`);
  },
  async RestoreProject(id: number) {
    const res = await this.createAxiosInstance().put(`/api/v1/project_archive/restore/${id}`);
    return res;
  },
  async allSectionsStatusDashboard(pageNumber: number) {
    try {
      const res = await this.createAxiosInstance().get(`/api/v1/dashboard/project/health?page=${pageNumber}&size=500`);
      return res;
    } catch (error) {
      return error;
    }
  },
  async allSectionsStatusDashboardByUser(pageNumber: number, userId: string) {
    try {
      const res = await this.createAxiosInstance().get(
        `/api/v1/dashboard/project/${userId}/health?page=${pageNumber}&size=500`
      );
      return res;
    } catch (error) {
      return error;
    }
  },

  // Return all User over all Project
  async getAllProjectUser() {
    try {
      const res = await this.createAxiosInstance().get(`/api/v1/project_user`);
      return res;

    } catch (error) {
      return error;
    }
  },

  // Return all User over all Archived Project
  async getAllArchivedProjectUser() {
    try {
      const res = await this.createAxiosInstance().get(`/api/v1/archived_project_user`);
      return res;

    } catch (error) {
      return error;
    }
  },

  async deleteUser(user_id: any) {
    try {
      const res = await this.createAxiosInstance().delete(`/api/v1/project-user/${user_id}`);
      return res;
    } catch (error) {
      return error;
    }
  },

  async GetAllVwaLetters() {
    const res = await this.createAxiosInstance().get("/api/v1/vwa-letters");
    return res;
  },
};

const SceneProject = {
  createAxiosInstance() {
    const axiosProjects = axios.create({
      baseURL: process.env.VUE_APP_PROJECT,
      headers: {
        // @ts-ignore
        Authorization: "Bearer " + router.app.$keycloak.token,
        Accept: "application/json"
        // 'Content-Type': 'application/json'
      }
    });
    return axiosProjects;
  },
  async GetAllScenesInTheProject(projectId: string) {
    return await this.createAxiosInstance().get(`/api/v1/project/${projectId}/scene`);
  },
  async GetActiveScene(projectId: number) {
    const res: AxiosResponse = await this.createAxiosInstance().get(`/api/v1/project/${projectId}/params/scene`);
    return res;
  },
  async SetActiveScene(projectId: number, sceneId: number) {
    const res: AxiosResponse = await this.createAxiosInstance().put(`/api/v1/project/${projectId}/params/scene`, {
      sceneId
    });
    return res;
  },
  async SwitchScene(projectId: number, sceneId: number) {
    return await this.createAxiosInstance().put(`/api/v1/project/${projectId}/params/scene`, { id: sceneId });
  }
};

const ProjectSectionDetails = {
  createAxiosInstance() {
    const axiosProjects = axios.create({
      baseURL: process.env.VUE_APP_PROJECT,
      headers: {
        // @ts-ignore
        Authorization: "Bearer " + router.app.$keycloak.token,
        Accept: "application/json"
        // 'Content-Type': 'application/json'
      }
    });
    return axiosProjects;
  },
  async GetSectionDetailsByDeviceSerialNumber(serialNumber: string) {
    const res = await this.createAxiosInstance().get(`/api/v1/section_info/${serialNumber}`);
    return res;
  },
  async GetAllSectionsInTheProject(projectId: string) {
    const res = await this.createAxiosInstance().get(`/api/v1/project/${projectId}/section`);

    SortingOrdinalNumbers(res.data.sections);
    return res;
  },
  async GetSectionDetails(sectionID: string) {
    const res = await this.createAxiosInstance().get(`/api/v1/section/${sectionID}`);
    return res;
  },
  async CreateNewSectionInTheProject(sectionToCreate: Section) {
    const res = await this.createAxiosInstance().post(`/api/v1/section/`, sectionToCreate);
    return res;
  },
  async UpdateSectionDetails(sectionToUpdate: any) {
    const res = await this.createAxiosInstance().put(`/api/v1/section/${sectionToUpdate.id}`, sectionToUpdate);
    return res;
  },
  async DeleteSectionInTheProject(id: string) {
    const res = await this.createAxiosInstance().delete(`/api/v1/section/${id}`, {
      data: { id: id }
    });
    return res.status;
  },
  async GetAllSectionTypes() {
    const res = await this.createAxiosInstance().get("/api/v1/section_type");
    return res.data.sectionTypes;
  },
  async GetAllSectionSubTypes() {
    const res = await this.createAxiosInstance().get("/api/v1/section_subtype");
    return res.data.sectionTypes;
  },
  // Display types
  async GetAllDisplayTypes() {
    const res = await this.createAxiosInstance().get("/api/v1/display_type");
    return res.data.displayTypes;
  },
  async GetSectionState(sectionId: string) {
    const res = await this.createAxiosInstance().get(`/api/v1/section/${sectionId}/state`);
    return res;
  },
  async GetAllSectionStatus(projectId: string) {
    const res = await this.createAxiosInstance().get(`/api/v1/project/${projectId}/state`);
    return res;
  },
  async ResetTrafficCount(id: string, observeFrom: Date, observeTo: Date) {
    const res = await this.createAxiosInstance().put(`/api/v1/section/${id}/params`, {
      observeFrom,
      observeTo
    });
    return res;
  },
  async SetMeasureAndCameraSectionSettings(id: number, sectionTypeApi: string, measureCameraSectionSettings: object) {
    const res = await this.createAxiosInstance().put(
      `/api/v1/section/${id}/settings/${sectionTypeApi}`,
      measureCameraSectionSettings
    );
    return res;
  },
  async SetCwuCustomFilenameSettings(sectionId: number, cwuCustomFilenameSettings: object) {
    const res = await this.createAxiosInstance().put(
      `/api/v1/section/${sectionId}/settings/cwu/customFileNames`,
      cwuCustomFilenameSettings
    );
    return res;
  },
  async SetSectionDeviceState(sectionId: number, state: object) {
    const res = await this.createAxiosInstance().put(`/api/v1/section/${sectionId}/deviceState`, state);
    return res;
  },
  async sendSupportNotification(data: any) {
    const res = await this.createAxiosInstance().post(`/api/v1/section/support/${data.sectionId}/${data.boardId}`, data.data);
    return res;
  }



};
const ProjectSectionDisplays = {
  createAxiosInstance() {
    const axiosProjects = axios.create({
      baseURL: process.env.VUE_APP_PROJECT,
      headers: {
        // @ts-ignore
        Authorization: "Bearer " + router.app.$keycloak.token,
        Accept: "application/json"
        // 'Content-Type': 'application/json'
      }
    });
    return axiosProjects;
  },
  async UpdateFPUSettings(fpuSettings: object, sectionId: number) {
    const res = await this.createAxiosInstance().put(`/api/v1/section/${sectionId}/settings/fpu`, fpuSettings);
    return res;
  },

  async GetDisplayDetails(displayId: number) {
    const res = await this.createAxiosInstance().get(`/api/v1/display_type/${displayId}`);
    return res;
  }
};
const ProjectSectionOverride = {
  createAxiosInstance() {
    const axiosProjects = axios.create({
      baseURL: process.env.VUE_APP_PROJECT,
      headers: {
        // @ts-ignore
        Authorization: "Bearer " + router.app.$keycloak.token,
        Accept: "application/json"
        // 'Content-Type': 'application/json'
      }
    });
    return axiosProjects;
  },
  async GetLatestPictureCameraSection(cameraId: string): Promise<AxiosResponse<any> | unknown> {
    try {
      const res = await this.createAxiosInstance().get(`/api/v1/section/camera/${cameraId}/current`, {
        headers: {
          Accept: "image/jpeg",
          "content-type": "image/jpeg"
        },
        responseType: "arraybuffer"
      });
      const image = btoa(new Uint8Array(res.data).reduce((data, byte) => data + String.fromCharCode(byte), ""));
      const img64 = `data:${res.headers["content-type"].toLowerCase()};base64,${image}`;
      res.data = img64;
      return res;
    } catch (error) {
      return error;
    }
  },
  async GetCameraSectionSetting(sectionId: string) {
    const res = await this.createAxiosInstance().get(`/api/v1/section/${sectionId}/camera`);
    return res;
  },
  async UpdateCameraSectionSetting(sectionId: number, cameraSettings: CameraSettings) {
    const res = await this.createAxiosInstance().put(`/api/v1/section/${sectionId}/camera`, cameraSettings);
    return res;
  },
  async manualOverrideToggle(sectionId: string, MOSettings: object) {
    const res = await this.createAxiosInstance().put(`/api/v1/section/${sectionId}/settings/vsu`, MOSettings);
    return res;
  },
  // interval
  async CreateNewIntervalInTheSection(newInterval: Interval) {
    try {
      const res = await this.createAxiosInstance().post("/api/v1/interval", newInterval);

      return res;
    } catch (error) {
      return error;
    }
  },
  async GetSectionIntervalFromTheBackEnd(sectionId: string) {
    const res = await this.createAxiosInstance().get(`/api/v1/section/${sectionId}/interval`);
    return res;
  },
  async UpdateIntervalInSection(intervalToUpdate: Interval) {
    const res = await this.createAxiosInstance().put(`/api/v1/interval/${intervalToUpdate.id}`, intervalToUpdate);
    return res;
  },
  async DeleteInterval(id: string) {
    const res = await this.createAxiosInstance().delete(`/api/v1/interval/${id}`, {
      data: { id: id }
    });
    return res;
  },
  async GetIntervalDetails(id: number) {
    const res = await this.createAxiosInstance().get(`/api/v1/interval/${id}`);
    return res;
  },
  // Manual Override
  async manualOverrideToggleCWU(sectionId: string, MOSettings: object) {
    const res = await this.createAxiosInstance().put(`/api/v1/section/${sectionId}/settings/cwu`, MOSettings);
    return res;
  },
  // Manual Override
  async manualOverrideToggleVWA(sectionId: string, MOSettings: object) {
    const res = await this.createAxiosInstance().put(`/api/v1/section/${sectionId}/settings/vwa`, MOSettings);
    return res;
  },
  async manualOverrideToggleScene(sectionId: string, MOSettings: object) {
    const res = await this.createAxiosInstance().put(`/api/v1/section/${sectionId}/settings/scene`, MOSettings);
    return res;
  },
  async ManualOverrideSingOptions(sectionId: string) {
    const res = exampleDisplaySings;
    return res;
  },
  // Project Update Threshold
  async UpdateThreshold(projectId: string, threshold: object) {
    const res = await this.createAxiosInstance().put(`/api/v1/project/${projectId}/params/cwu`, threshold);
    return res;
  },
  async GetCurrentThreshold(projectId: string) {
    const res = await this.createAxiosInstance().get(`/api/v1/project/${projectId}/params/cwu`);
    return res;
  },
  async getThresholdTable(projectId: string) {
    const res = await this.createAxiosInstance().get(`/api/v1/project/${projectId}/switch-table`);
    return res;
  }
};
const ProjectProfilesService = {
  createAxiosInstance() {
    const axiosProjects = axios.create({
      baseURL: process.env.VUE_APP_PROJECT,
      headers: {
        // @ts-ignore
        Authorization: "Bearer " + router.app.$keycloak.token,
        Accept: "application/json"
        // 'Content-Type': 'application/json'
      }
    });
    return axiosProjects;
  },
  async GetAllProjectProfiles() {
    try {
      const res = await this.createAxiosInstance().get(`/api/v1/switching-profile`);
      return res;
    } catch (error) {
      return error;
    }
  },
  async GetProjectProfileDetails(profileId: string) {
    try {
      const res = await this.createAxiosInstance().get(`/api/v1/switching-profile/${profileId}`);
      return res;
    } catch (error) {
      return error;
    }
  },
  async CreateNewProjectProfile(newProjectProfile: ProjectProfile) {
    try {
      const res = await this.createAxiosInstance().post(`/api/v1/switching-profile`, newProjectProfile);
      return res;
    } catch (error) {
      return error;
    }
  },
  async UpdateProjectProfile(projectProfileId: string, projectProfileToUpdate: ProjectProfile) {
    try {
      const res = await this.createAxiosInstance().put(
        `/api/v1/switching-profile/${projectProfileId}`,
        projectProfileToUpdate
      );
      return res;
    } catch (error) {
      return error;
    }
  },
  async DeleteProjectProfile(projectProfileId: string) {
    try {
      const res = await this.createAxiosInstance().delete(`/api/v1/switching-profile/${projectProfileId}`, {
        data: { id: projectProfileId }
      });
      return res;
    } catch (error) {
      return error;
    }
  },
  // get profiles for specific project
  async GetAllProjectProfilesForProject(projectId: number) {
    try {
      const res = await this.createAxiosInstance().get(`/api/v1/switching-profile-by-project/${projectId}`);
      return res;
    } catch (error) {
      return error;
    }
  },
  async GetIntervalsInProfile(profileId: number) {
    try {
      const res = await this.createAxiosInstance().get(`/api/v1/switching-profile/${profileId}/intervals`);
      return res;
    } catch (error) {
      return error;
    }
  },
  async GetSectionProfile(sectionId: number) {
    try {
      const res = await this.createAxiosInstance().get(`/api/v1/switching-profile-for/${sectionId}`);
      return res;
    } catch (error) {
      return error;
    }
  }
};
const ProjectTypeRZP = {
  createAxiosInstance() {
    const axiosProjects = axios.create({
      baseURL: process.env.VUE_APP_PROJECT,
      headers: {
        // @ts-ignore
        Authorization: "Bearer " + router.app.$keycloak.token,
        Accept: "application/json"
        // 'Content-Type': 'application/json'
      }
    });
    return axiosProjects;
  },
  async GetActiveScene(projectId: string) {
    const res: AxiosResponse = await this.createAxiosInstance().get(`/api/v1/project/${projectId}/params/rzu`);
    return res;
  },
  async SetActiveScene(projectId: string, stateId: string) {
    const res: AxiosResponse = await this.createAxiosInstance().put(`/api/v1/project/${projectId}/params/rzu`, {
      stateId
    });
    return res;
  },
  async GetAllScenesOptions(projectId: string) {
    const res: AxiosResponse = await this.createAxiosInstance().get(`/api/v1/project/${projectId}/switch-table`);
    return res;
  }
};
const CountryService = {
  createAxiosInstance() {
    const axiosProjects = axios.create({
      baseURL: process.env.VUE_APP_PROJECT,
      headers: {
        // @ts-ignore
        Authorization: "Bearer " + router.app.$keycloak.token,
        Accept: "application/json"
        // 'Content-Type': 'application/json'
      }
    });
    return axiosProjects;
  },
  async GetCountryList() {
    const res = await this.createAxiosInstance().get(`/api/v1/country`);
    return res;
  },
  async GetStatesList(countryId: number) {
    const res = await this.createAxiosInstance().get(`/api/v1/country/${countryId}/states`);
    return res;
  }
};
const ProjectReportExport = {
  createAxiosInstance() {
    const axiosProjects = axios.create({
      baseURL: process.env.VUE_APP_PROJECT,
      headers: {
        // @ts-ignore
        Authorization: "Bearer " + router.app.$keycloak.token,
        Accept: "application/json"
        // 'Content-Type': 'application/json'
      }
    });
    return axiosProjects;
  },
  async GetGeneratedReport(reportOptions: ReportOptions) {
    const res: AxiosResponse = await this.createAxiosInstance().get(
      `/api/v1/switching-report/${reportOptions.projectId}?from=${reportOptions.startDate}&to=${reportOptions.endDate}`,
      {
        headers: {
          Accept: "text/plain"
        }
      }
    );
    return res;
  }
};

const exampleDisplaySings = i18n.t("project.section.displayInterval.displaySignOptions");

export {
  Projects,
  ProjectSectionDetails,
  ProjectSectionDisplays,
  ProjectSectionOverride,
  ProjectProfilesService,
  SceneProject,
  CountryService,
  ProjectReportExport,
  ProjectTypeRZP
};
